import React, {Component} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {xtrahelperApi} from "../../api/xtrahelper-api";
import {handleLogError} from "../../utils/utils";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {AuthContext} from "../../auth/AuthContextProvider";

class Accounts extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            addAccountOpen: false,
            accounts: []
        }
    }

    getAccounts() {
        xtrahelperApi.getAccounts(this.context.user)
            .then(response => {
                const accounts = response.data.map(item => {
                    return {
                        id: item.id,
                        name: item.name,
                        listingsCount: item.listingsCount,
                        url: item.url,
                        lastUpdated: (item.lastUpdated && new Date(item.lastUpdated).toLocaleString()) || ""
                    }
                })
                this.setState({accounts: accounts})
            })
            .catch(error => handleLogError(error))
    }

    componentDidMount() {
        this.getAccounts()
    }

    handleClickOpen() {
        this.setState({
            addAccountOpen: true
        })
    }

    handleClickClose() {
        this.setState({
            addAccountOpen: false
        })
    }

    handleAddNewAccount = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const id = data.get('id')
        const name = data.get('name')
        xtrahelperApi.addAccount(this.context.user, id, name)
            .then(response => {
                this.getAccounts()
            })
        this.handleClickClose()
    }

    render() {
        const columns = [
            {
                field: "id",
                headerName: "Id",
                width: 150
            },
            {
                field: "name",
                headerName: "Name",
                width: 200
            },
            {
                field: "listingsCount",
                headerName: "Listings Count",
                width: 250
            },
            {
                field: "url",
                headerName: "Link",
                width: 550
            },
            {
                field: "lastUpdated",
                headerName: "Last Updated",
                width: 180
            },
        ]
        return (
            <Box>
                <Container sx={{my: 1}}>
                    <Button variant="contained" onClick={() => this.handleClickOpen()}>
                        Add New Account
                    </Button>
                </Container>
                <Dialog open={this.state.addAccountOpen} onClose={() => this.handleClickClose()}>
                    <DialogTitle>Add New Account</DialogTitle>
                    <Box component="form" onSubmit={this.handleAddNewAccount}>
                        <DialogContent>
                            <TextField
                                autoFocus
                                required
                                id="id"
                                name="id"
                                label="Id"
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                required
                                id="name"
                                name="name"
                                label="Name"
                                fullWidth
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" variant="contained">Add Account</Button>
                            <Button onClick={() => this.handleClickClose()}>Close</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
                <div style={{height: 630, width: '100%'}}>
                    <DataGrid columns={columns} rows={this.state.accounts}/>
                </div>
            </Box>
        );
    }
}

export default Accounts;
