import React, {Component} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import XtraHelper from '../components/XtraHelper'
import Listings from '../components/listings/Listings'
import Dashboard from '../components/dashboard/Dashboard'
import Models from "../components/models/Models";
import Accounts from "../components/accounts/Accounts";
import OwnListings from "../components/own-listings/OwnListings";

class AuthenticatedRoutingScheme extends Component {
    render() {
        return (
            <Routes>
                <Route path="/" element={<XtraHelper/>}>
                    <Route path="accounts" element={<Accounts/>}/>
                    <Route path="listings" element={<Listings/>}/>
                    <Route path="ownListings" element={<OwnListings/>}/>
                    <Route path="models" element={<Models/>}/>
                </Route>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Routes>
        )
    }
}

export default AuthenticatedRoutingScheme
