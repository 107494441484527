import React, {useEffect} from 'react';
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {FormControl, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import {xtrahelperApi} from "../../api/xtrahelper-api";
import {handleLogError} from "../../utils/utils";
import {AuthContext} from "../../auth/AuthContextProvider";
import {DataGrid} from "@mui/x-data-grid";
import Link from "@mui/material/Link";

export default function Listings() {
    const context = React.useContext(AuthContext)

    const [model, setModel] = React.useState('')
    const [models, setModels] = React.useState([])

    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    const [startDate, setStartDate] = React.useState(threeDaysAgo)

    useEffect(() => {
        xtrahelperApi.getModelsByActive(context.user, true)
            .then(response => {
                const respData = response.data.map(model => {
                    return {
                        id: model.id,
                        name: model.name
                    }
                })
                respData.sort((a, b) => (a.name > b.name) ? 1 : -1)
                setModels(respData)
            })
            .catch(error => handleLogError(error))
    }, [])

    const [listings, setListings] = React.useState([])

    useEffect(() => {
        console.log("Model: " + model)
        if (!model) {
            return
        }
        xtrahelperApi.getListingsByModelAndTimestamp(context.user, model, startDate)
            .then(response => {
                const respData = response.data.map(listing => {
                    return {
                        id: listing.id,
                        name: listing.name,
                        location: listing.location,
                        currentPrice: listing.price.current,
                        originalPrice: listing.price.original,
                        link: listing.canonicalLink,
                        timestamp: listing.timestamp
                    }
                })
                respData.sort((a, b) => (a.name > b.name) ? 1 : -1)
                setListings(respData)
            })
            .catch(error => handleLogError(error));
    }, [model, startDate])

    const handleModelChange = (event) => {
        event.preventDefault()
        setModel(event.target.value);
    }

    const modelNameItems = (models) => {
        return models.map(model => <MenuItem key={model.id} value={model.id}>{model.name}</MenuItem>)
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            renderCell: ({value}) => (
                <Tooltip title={value}>
                    <span>{value}</span>
                </Tooltip>
            ),
            width: 500
        },
        {
            field: "location",
            headerName: "Location",
            width: 160
        },
        {
            field: "currentPrice",
            headerName: "Current Price",
            width: 100
        },
        {
            field: "originalPrice",
            headerName: "Original Price",
            width: 100
        },
        {
            field: "link",
            headerName: "Link",
            renderCell: ({value}) => <Link rel="noopener noreferrer" href={value} target="_blank">Open on FB</Link>,
            width: 100
        },
        {
            field: "timestamp",
            headerName: "Timestamp",
            type: 'dateTime',
            valueGetter: ({value}) => value && new Date(value),
            width: 170
        },
    ]

    return (
        <>
            <Container key='select' sx={{mt: 3}}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                    }}
                >
                    <FormControl key="model" required sx={{minWidth: '30%'}}>
                        <InputLabel id="model-select-label">Model</InputLabel>
                        <Select
                            labelId="model-select-label"
                            id="model-select"
                            value={model}
                            label="model"
                            onChange={handleModelChange}
                        >
                            <MenuItem key='none' value=''>
                                <em>None</em>
                            </MenuItem>
                            {modelNameItems(models)}
                        </Select>
                    </FormControl>
                    <TextField
                        id="date"
                        label="Start Date"
                        type="date"
                        defaultValue={startDate.toISOString().split('T')[0]}
                        sx={{width: 220, ml: 2}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => setStartDate(new Date(event.target.value))}
                    />
                </Paper>
            </Container>
            <Container key='grid' sx={{mt: 3}}>
                <div style={{height: 630, width: '100%'}}>
                    <DataGrid columns={columns} rows={listings}/>
                </div>
            </Container>
        </>
    );
}
