import React, {Component} from 'react'
import {AuthContext} from "../auth/AuthContextProvider"
import AuthenticatedRoutingScheme from "./AuthenticatedRoutingScheme"
import UnauthenticatedRoutingScheme from "./UnauthenticatedRoutingScheme"

class RoutingSchemeProvider extends Component {
    static contextType = AuthContext

    render() {
        const isAuthenticated = this.context.isUserAuthenticated()
        const routingScheme = isAuthenticated
            ? <AuthenticatedRoutingScheme/>
            : <UnauthenticatedRoutingScheme/>
        return (
            routingScheme
        )
    }
}

export default RoutingSchemeProvider
