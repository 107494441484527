import * as React from 'react';
import {LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip} from 'recharts';
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {xtrahelperApi} from "../../api/xtrahelper-api";
import {AuthContext} from "../../auth/AuthContextProvider";
import {handleLogError} from "../../utils/utils";

export default function OwnListingChart(props) {
    const [data, setData] = React.useState([])
    const context = React.useContext(AuthContext)
    useEffect(() => {
        if (!props.account && !props.listing) {
            return
        }
        if (props.listing) {
            xtrahelperApi.getTimeSeriesByListing(context.user, props.listing, props.startDate, props.endDate, props.interpolation)
                .then(response => {
                    const respData = response.data.map(point => {
                        return {
                            time: new Date(point.timestamp).toLocaleString(),
                            clicks: point.value
                        }
                    })
                    setData(respData)
                })
                .catch(error => handleLogError(error));
        } else {
            xtrahelperApi.getTimeSeriesByAccount(context.user, props.account, props.startDate, props.endDate, props.interpolation)
                .then(response => {
                    const respData = response.data.map(point => {
                        return {
                            time: new Date(point.timestamp).toLocaleString(),
                            clicks: point.value
                        }
                    })
                    setData(respData)
                })
                .catch(error => handleLogError(error));
        }
    }, [props.account, props.listing, props.startDate, props.endDate, props.interpolation])
    return (
        <React.Fragment>
            <Typography>
                Own Listings Clicks
            </Typography>
            <ResponsiveContainer>
                <LineChart
                    data={data}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis fontSize={12}
                        dataKey="time"
                    />
                    <YAxis
                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{
                                textAnchor: 'middle',
                            }}
                        >
                            Clicks
                        </Label>
                    </YAxis>
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                    <Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="clicks"
                        activeDot={{r: 6}}
                        // dot={false}
                    />
                    <Tooltip/>
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
