import axios from "axios";
import {urlConfig} from "./url-config";
import {parseJwt} from "../utils/utils";

// -- Axios
const instance = axios.create({
    baseURL: urlConfig.url.API_BASE_URL
})

instance.interceptors.request.use(function (request) {
    // If token is expired, redirect user to login
    if (request.headers.Authorization) {
        const token = request.headers.Authorization.split(' ')[1]
        const data = parseJwt(token)
        if (Date.now() > data.exp * 1000) {
            window.location.href = "/"
        }
    }
    return request
}, function (error) {
    return Promise.reject(error)
})

function authenticate(username, password) {
    return instance.post('/auth/authenticate', {username, password}, {
        headers: {'Content-type': 'application/json'}
    })
}

function getAccounts(user) {
    return instance.get('/accounts', {
        headers: {
            'Authorization': getBearer(user)
        }
    })
}

function addAccount(user, id, name) {
    return instance.post('/accounts', {id: id, name: name}, {
        headers: {
            'Authorization': getBearer(user),
            'Content-type': 'application/json'
        }
    })
}

function getOwnListingsByAccount(user, accountId) {
    return instance.get('/ownListings?accountId=' + accountId, {
        headers: {
            'Authorization': getBearer(user)
        }
    })
}

function getTimeSeriesByListing(user, listing, startDate, endDate, interpolation) {
    const localStartDate = startDate.getTime() + startDate.getTimezoneOffset() * 60000
    const localEndDate = endDate.getTime() + endDate.getTimezoneOffset() * 60000
    const url = '/ownListings/' + listing +
        '/ts?startDate=' + localStartDate +
        '&endDate=' + localEndDate +
        '&interpolateInterval=' + interpolation
    return instance.get(url, {
        headers: {
            'Authorization': getBearer(user)
        }
    })
}

function getTimeSeriesByAccount(user, account, startDate, endDate, interpolation) {
    const localStartDate = startDate.getTime() + startDate.getTimezoneOffset() * 60000
    const localEndDate = endDate.getTime() + endDate.getTimezoneOffset() * 60000
    const url = '/accounts/' + account +
        '/ts?startDate=' + localStartDate +
        '&endDate=' + localEndDate +
        '&interpolateInterval=' + interpolation
    return instance.get(url, {
        headers: {
            'Authorization': getBearer(user)
        }
    })
}

function getModels(user) {
    return instance.get('/models', {
        headers: {
            'Authorization': getBearer(user)
        }
    })
}

function getModelsByActive(user, active) {
    return instance.get('/models?active=' + active, {
        headers: {
            'Authorization': getBearer(user)
        }
    })
}

function upsertModel(user, model) {
    return instance.post('/models', model, {
        headers: {
            'Authorization': getBearer(user),
            'Content-type': 'application/json'
        }
    })
}

function deleteModel(user, modelId) {
    return instance.delete('/models/' + modelId, {
        headers: {
            'Authorization': getBearer(user),
            'Content-type': 'application/json'
        }
    })
}

function getListingsByModel(user, model) {
    return instance.get('/listings?modelId=' + model, {
        headers: {
            'Authorization': getBearer(user)
        }
    })
}

function getListingsByModelAndTimestamp(user, model, from) {
    const localFrom = from.getTime() + from.getTimezoneOffset() * 60000

    return instance.get('/listings?modelId=' + model + '&from=' + localFrom, {
        headers: {
            'Authorization': getBearer(user)
        }
    })
}

function getBearer(user) {
    return "Bearer " + user.accessToken
}

export const xtrahelperApi = {
    authenticate,
    getAccounts,
    addAccount,
    getOwnListingsByAccount,
    getTimeSeriesByListing,
    getTimeSeriesByAccount,
    getModels,
    getModelsByActive,
    upsertModel,
    deleteModel,
    getListingsByModel,
    getListingsByModelAndTimestamp
}
