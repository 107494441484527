const prod = {
    url: {
        API_BASE_URL: 'https://api.xtralife.link',
    }
}

const dev = {
    url: {
        API_BASE_URL: 'http://localhost:8081'
    }
}

export const urlConfig = process.env.NODE_ENV === 'development' ? dev : prod
