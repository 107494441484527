import React, {Component} from 'react'
import {Navigate, Route, Routes} from "react-router-dom"
import SignIn from "../components/SignIn"

class UnauthenticatedRoutingScheme extends Component {
    render() {
        return (
            <Routes>
                <Route path="/" element={<SignIn/>}/>
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Routes>
        )
    }
}

export default UnauthenticatedRoutingScheme
