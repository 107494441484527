import React from "react";

const AuthContext = React.createContext({})

class AuthContextProvider extends React.Component {
    state = {user: JSON.parse(localStorage.getItem('user'))}

    userLogin = (user) => {
        localStorage.setItem('user', JSON.stringify(user))
        this.setState({user: user})
    }

    userLogout = () => {
        localStorage.removeItem('user')
        this.setState({user: null})
    }

    isUserAuthenticated = () => {
        const user = this.state.user
        if (!user) {
            return false
        }
        if (!user.data) {
            this.setState({user: null})
            return false
        }

        // if user has token expired, logout user
        // todo add token refresh logic
        if (Date.now() > user.data.exp * 1000) {
            this.userLogout()
            return false
        }
        return true
    }

    render() {
        const children = this.props.children
        const contextValue = {
            user: this.state.user,
            userLogin: this.userLogin,
            userLogout: this.userLogout,
            isUserAuthenticated: this.isUserAuthenticated
        }
        return (
            <AuthContext.Provider value={contextValue}>
                {children}
            </AuthContext.Provider>
        )
    }
}

export {AuthContext, AuthContextProvider}
