import React from "react"
import {AuthContextProvider} from "./auth/AuthContextProvider"
import RoutingSchemeProvider from "./routing/RoutingSchemeProvider"
import {BrowserRouter as Router} from "react-router-dom"

class App extends React.Component {
    render() {
        return (
            <AuthContextProvider>
                <Router>
                    <RoutingSchemeProvider/>
                </Router>
            </AuthContextProvider>
        );
    }
}

export default App
