import React, {useEffect} from 'react';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import {xtrahelperApi} from "../../api/xtrahelper-api";
import {AuthContext} from "../../auth/AuthContextProvider";
import {handleLogError} from "../../utils/utils";
import {DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer} from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Paper from "@mui/material/Paper";

export default function Models() {
    const context = React.useContext(AuthContext)
    const newId = 'new'

    const [models, setModels] = React.useState([])
    const [rowModesModel, setRowModesModel] = React.useState({})

    const [doRefresh, setDoRefresh] = React.useState({})

    const [isAddingNewModel, setAddingNewModel] = React.useState(false)

    useEffect(() => {
        xtrahelperApi.getModels(context.user)
            .then(response => {
                const respData = response.data.map(model => {
                    return {
                        id: model.id,
                        name: model.name,
                        active: model.active,
                        createdAt: model.createdAt
                    }
                })
                setModels(respData)
            })
            .catch(error => handleLogError(error))
    }, [doRefresh])

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => (event) => {
        console.log("save click " + id)
        console.log(event)
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
        setAddingNewModel(false)
    };

    const handleDeleteClick = (id) => async () => {
        console.log("Delete row was clicked for row " + id)
        await xtrahelperApi.deleteModel(context.user, id)
        setModels(models.filter((model) => model.id !== id))
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedModel = models.find((model) => model.id === id);
        if (editedModel.isNew) {
            setModels(models.filter((model) => model.id !== id));
        }
        setAddingNewModel(false)
    };

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true
    }

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true
    }

    const processRowUpdate = async (newModel, oldModel) => {
        console.log('Row was updated')
        console.log(newModel)
        console.log(oldModel)
        const isNew = newModel.isNew
        let updatedModel = {...newModel, isNew: false};
        if (isNew) {
            updatedModel = {...updatedModel, createdAt: Date.now()}
        }
        await xtrahelperApi.upsertModel(context.user, updatedModel)

        setModels(models.map((model) => ((newModel.isNew && model.id === newId) || (model.id === newModel.id) ? updatedModel : model)))
        return updatedModel
    }

    const isCellEditable = (params) => {
        if (params.field !== 'id') {
            return true
        }
        return !!params.row.isNew
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 120,
            editable: true,
            preProcessEditCellProps: (params) => {
                console.log('Validation')
                console.log(params)
                const hasError = params.props.value === newId
                return {...params.props, error: hasError}
            },
        },
        {
            field: "name",
            headerName: "Name",
            width: 600,
            editable: true
        },
        {
            field: 'active',
            headerName: 'Is Active',
            type: 'boolean',
            width: 100,
            editable: true
        },
        {
            field: "createdAt",
            headerName: "Created At",
            type: 'dateTime',
            valueGetter: ({value}) => value && new Date(value),
            width: 200
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            label='Save'
                            icon={<SaveIcon/>}
                            color='primary'
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            label='Cancel'
                            icon={<CancelIcon/>}
                            className='textPrimary'
                            color='inherit'
                            onClick={handleCancelClick(id)}
                        />
                    ]
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ]
            }
        }
    ]

    function EditToolbar(props) {
        const {setModels, setRowModesModel} = props;

        const handleAddModelClick = () => {
            setAddingNewModel(true)
            console.log("I was clicked");
            const id = newId
            setModels((oldModels) => [...oldModels, {id, name: '', isActive: false, createdAt: '', isNew: true}]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: {mode: GridRowModes.Edit, fieldToFocus: 'id'},
            }));
        }
        return (
            <GridToolbarContainer sx={{ml: 1, mt: 1}}>
                <Button color='primary' variant='outlined' startIcon={<AddIcon/>} disabled={isAddingNewModel}
                        onClick={handleAddModelClick}>
                    Add new model
                </Button>
            </GridToolbarContainer>
        )
    }

    return (
        <Container
            sx={{mt: 3, height: 'calc(100% - 100px)', display: 'flex'}}
        >
            <Paper style={{flexGrow: 1, width: '100%'}}>
                <DataGrid
                    sx={{
                        '& .Mui-error': {
                            backgroundColor: `rgb(126, 10, 15, 0.1)`,
                            color: '#750f0f',
                            height: '100%'
                        }
                    }}
                    columns={columns}
                    rows={models}
                    editMode='row'
                    isCellEditable={isCellEditable}
                    components={{
                        Toolbar: EditToolbar
                    }}
                    componentsProps={{
                        toolbar: {setModels, setRowModesModel}
                    }}
                    rowModesModel={rowModesModel}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    experimentalFeatures={{newEditingApi: true}}
                />
            </Paper>
        </Container>
    )
}
