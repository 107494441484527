import Paper from "@mui/material/Paper";
import * as React from "react";
import Container from "@mui/material/Container";
import OwnListingChart from "./OwnListingChart";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {AuthContext} from "../../auth/AuthContextProvider";
import {useEffect} from "react";
import {xtrahelperApi} from "../../api/xtrahelper-api";
import {handleLogError} from "../../utils/utils";

export default function OwnListings() {
    const context = React.useContext(AuthContext)

    const [accounts, setAccounts] = React.useState([])
    const [account, setAccount] = React.useState('')
    useEffect(() => {
        xtrahelperApi.getAccounts(context.user)
            .then(response => {
                const respData = response.data.map(account => {
                    return {
                        id: account.id,
                        name: account.name
                    }
                })
                setAccounts(respData)
            })
            .catch(error => handleLogError(error))
    }, [])

    const handleAccountChange = (event) => {
        event.preventDefault()
        setAccount(event.target.value);
        setListings([]);
        setListing('')
    }

    const accountNameItems = (accounts) => {
        return accounts.map(account => <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>)
    };

    const [listings, setListings] = React.useState([])
    const [listing, setListing] = React.useState('')
    useEffect(() => {
        if (!account) {
            return;
        }
        xtrahelperApi.getOwnListingsByAccount(context.user, account)
            .then(response => {
                const respData = response.data.map(listing => {
                    return {
                        key: listing.key,
                        name: '(' + listing.clicks +') ' + listing.name + ' - ' + (listing.price && listing.price.current || '')
                    }
                })
                setListings(respData)
            })
            .catch(error => handleLogError(error));
    }, [account])

    const handleListingChange = (event) => {
        event.preventDefault()
        setListing(event.target.value);
    }

    const listingNameItems = (listings) => {
        return listings.map(listing => <MenuItem key={listing.key} value={listing.key}>{listing.name}</MenuItem>)
    };

    const monthAgo = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);
    const [startDate, setStartDate] = React.useState(monthAgo)
    const [endDate, setEndDate] = React.useState(new Date())

    const [interpolation, setInterpolation] = React.useState("3h")

    const handleInterpolationChange = (event) => {
        event.preventDefault()
        setInterpolation(event.target.value);
    }

    return (
        <>
            <Container sx={{mt: 3}}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                    }}
                >
                    <FormControl key="account" required sx={{minWidth: '30%'}}>
                        <InputLabel id="account-select-label">Account</InputLabel>
                        <Select
                            labelId="account-select-label"
                            id="account-select"
                            value={account}
                            label="Account"
                            onChange={handleAccountChange}
                        >
                            <MenuItem key='none' value=''>
                                <em>None</em>
                            </MenuItem>
                            {accountNameItems(accounts)}
                        </Select>
                        <FormHelperText>Select Facebook Account</FormHelperText>
                    </FormControl>
                    <FormControl key="listing" required sx={{ml: 3, flexGrow: 2}}>
                        <InputLabel id="listing-select-label">Listing</InputLabel>
                        <Select
                            labelId="listing-select-label"
                            id="listing-select"
                            value={listing}
                            label="Listing"
                            onChange={handleListingChange}
                        >
                            <MenuItem key='none' value=''>
                                <em>None</em>
                            </MenuItem>
                            {listingNameItems(listings)}
                        </Select>
                        <FormHelperText>Select Facebook Listing</FormHelperText>
                    </FormControl>
                </Paper>
            </Container>
            <Container sx={{mt: 2}}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                    }}
                >
                    <TextField
                        id="date"
                        label="Start Date"
                        type="date"
                        defaultValue={startDate.toISOString().split('T')[0]}
                        sx={{width: 220}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => setStartDate(new Date(event.target.value))}
                    />
                    <TextField
                        id="date"
                        label="End Date"
                        type="date"
                        defaultValue={endDate.toISOString().split('T')[0]}
                        sx={{width: 220, ml: 3}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => setEndDate(new Date(event.target.value))}
                    />
                    <FormControl key="interpolation" required sx={{ml: 3, minWidth: 220}}>
                        <InputLabel id="interpolation-select-label">Interpolation Interval</InputLabel>
                        <Select
                            labelId="interpolation-select-label"
                            id="interpolation-select"
                            value={interpolation}
                            label="Interpolation Interval"
                            onChange={handleInterpolationChange}
                        >
                            <MenuItem key='1h' value='1h'>
                                1 Hour
                            </MenuItem>
                            <MenuItem key='3h' value='3h'>
                                3 Hours <em>(default)</em>
                            </MenuItem>
                            <MenuItem key='6h' value='6h'>
                                6 Hours
                            </MenuItem>
                            <MenuItem key='12h' value='12h'>
                                12 Hours
                            </MenuItem>
                            <MenuItem key='1day' value='1day'>
                                1 Day
                            </MenuItem>
                        </Select>
                        <FormHelperText>Select Interpolation Interval</FormHelperText>
                    </FormControl>
                </Paper>
            </Container>
            <Container sx={{mt: 2}}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 400,
                    }}
                >
                    <OwnListingChart
                        account={account}
                        listing={listing}
                        startDate={startDate}
                        endDate={endDate}
                        interpolation={interpolation}
                    />
                </Paper>
            </Container>
        </>
    )
}
