import React from "react"
import {NavLink, Outlet} from "react-router-dom"
import {AuthContext} from "../auth/AuthContextProvider"
import {
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List, Menu, MenuItem,
    Toolbar, Tooltip,
    Typography
} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import GroupIcon from "@mui/icons-material/Group"
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContentCutIcon from "@mui/icons-material/ContentCut"
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';

const drawerWidth = 200

export default class XtraHelper extends React.Component {
    static contextType = AuthContext

    state = {
        userAnchorElement: null
    }

    handleOpenUserMenu = (event) => {
        this.setState({userAnchorElement: event.currentTarget})
    }

    handleCloseUserMenu = (setting) => {
        this.setState({userAnchorElement: null})
        if (setting === 'Logout') {
            this.context.userLogout()
        }
    }

    userLogout = () => {
        this.context.userLogout()
    }

    render() {
        return (
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position='absolute' open
                        sx={{
                            width: `calc(100% - ${drawerWidth}px)`
                        }}
                >
                    <Toolbar>
                        <Typography
                            component='h1'
                            variant='h6'
                            color='inherit'
                            noWrap
                            sx={{flexGrow: 1}}
                        >
                            Dashboard
                        </Typography>
                        <Tooltip title='Open settings'>
                            <IconButton color='inherit' onClick={this.handleOpenUserMenu}>
                                <AccountCircleIcon/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            open={Boolean(this.state.userAnchorElement)}
                            onClose={this.handleCloseUserMenu}
                            anchorEl={this.state.userAnchorElement}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            sx={{mt: '45px'}}
                            keepMounted
                        >
                            <MenuItem key='Logout' onClick={this.userLogout}>
                                <Typography textAlign='center'>Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant='permanent'
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            whiteSpace: 'nowrap'
                        }
                    }}
                >
                    <Toolbar/>
                    <Divider/>
                    <List component='nav'>
                        <ListItemButton
                            component={React.forwardRef(
                                (props, ref) => <NavLink to='/accounts' {...props} ref={ref}/>)}>
                            <ListItemIcon>
                                <GroupIcon/>
                            </ListItemIcon>
                            <ListItemText primary='Accounts'/>
                        </ListItemButton>
                        <ListItemButton
                            component={React.forwardRef(
                                (props, ref) => <NavLink to='/models' {...props} ref={ref}/>)}>
                            <ListItemIcon>
                                <AppSettingsAltIcon/>
                            </ListItemIcon>
                            <ListItemText primary='Models'/>
                        </ListItemButton>
                        <ListItemButton
                            component={React.forwardRef(
                                (props, ref) => <NavLink to='/listings' {...props} ref={ref}/>)}>
                            <ListItemIcon>
                                <ContentCutIcon/>
                            </ListItemIcon>
                            <ListItemText primary='Listings'/>
                        </ListItemButton>
                        <ListItemButton
                            component={React.forwardRef(
                                (props, ref) => <NavLink to='/ownListings' {...props} ref={ref}/>)}>
                            <ListItemIcon>
                                <SystemSecurityUpdateGoodIcon/>
                            </ListItemIcon>
                            <ListItemText primary='Own Listings'/>
                        </ListItemButton>
                    </List>
                </Drawer>
                <Box component='main'
                     sx={{
                         backgroundColor: '#f5f5f5',
                         flexGrow: 1,
                         height: `100vh`,
                         overflow: 'auto'
                     }}
                >
                    <Toolbar/>
                    <Outlet/>
                </Box>
            </Box>
        )
    }
}
